<template>
  <section class="main-bg m-mh">
    <navi-bar>
      {{$t('front.common.faq')}}
    </navi-bar>
    <cs-nav-mobile />
    <div class="writebtn mt10">
      <select class="select" v-model="faqType" >
        <option :value="'all'">{{$t('front.gameCategory.all')}}</option>
        <option :value="'casino'">{{$t('front.gameCategory.livecasino')}}</option>
        <option :value="'slot'">{{$t('front.gameCategory.slot')}}</option>
        <option :value="'cash'">{{$t('front.gameCategory.cash')}}</option>
        <option :value="'betInfo'">{{$t('front.gameCategory.betting')}}</option>
        <option :value="'etc'">{{$t('front.gameCategory.etc')}}</option>
      </select>
    </div>
    <!--div class="coupontab coupontab2">
      <a :class="{'on': faqType == 'all'}" @click="onSelectType('all')" href="#">전체</a>
      <a :class="{'on': faqType == 'casino'}" @click="onSelectType('casino')" href="#">카지노</a>
      <a :class="{'on': faqType == 'slot'}" @click="onSelectType('slot')" href="#">슬롯</a>
      <a :class="{'on': faqType == 'cash'}" @click="onSelectType('cash')" href="#">충/환전</a>
      <a :class="{'on': faqType == 'betInfo'}" @click="onSelectType('betInfo')" href="#">베팅관련</a>
      <a :class="{'on': faqType == 'etc'}" @click="onSelectType('etc')" href="#">기타</a-->
      <!--a href="#">스포츠</a>
      <a href="#">e-스포츠</a>
      <a href="#">미니게임</a>
      <a href="#">가상게임</a>
      <a href="#">피싱게임</a>
      <a href="#">로터리게임</a-->
    <!--/div-->

    <div class="msgwrap qnalistwrap">
      <template v-for="(item, index) in list" >
        <template v-if="item && (item.category == faqType || faqType == 'all')" >
          <ul v-bind:key="index + '_' + item.title">
            <li class="msgcon">
              <em class="qnaicon" v-if="item.codeName">{{item.codeName}}</em>
              <p class="subject">
                <span class="subjecttxt1">{{ item.title }}</span>
              </p>
              <span class="arrow" @click="item.isOpen = !item.isOpen">
              <img src="@/assets_mobile/img/icon_top_gn.svg"  v-if="!item.isOpen"/>
              <img src="@/assets_mobile/img/icon_bottom_gr.svg" v-if="item.isOpen"/>
            </span><!-- icon_bottom_gr -->
            </li>
            <li class="qnalistread progress" v-if="!item.isOpen" v-bind:key="index + '_' + item.title">
              <div class="writing" v-html="item.content">
              </div>
              <div class="reply" v-if="item.comment.length > 0">
                <p>{{item.comment[0]}}</p>
              </div>
            </li>
          </ul>
        </template>
      </template>
    </div>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import CsNavMobile from '@/views_mobile/member/cs/nav'

import faqList from '@/views/member/cscenter/faq/list'

export default {
  name: 'Coupon',
  components: {
    CsNavMobile,
    NaviBar
  },
  mixins: [
    faqList
  ],
  watch: {
    faqType: {
      handler () {
        this.onSelectType(this.faqType)
      }
    }
  }
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
